body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a {
  text-decoration: none !important;
  color: inherit;
}

/* editor */
.ql-editor {
  height: 200px !important;
}

.tagsList::-webkit-scrollbar {
  width: 5px;
  padding-right: 1px;
}

.tagsList::-webkit-scrollbar-thumb {
  background-color: #dddee4;
  border-radius: 20px;
}
